import { useParams } from "react-router";
import Question from "../components/question";
import TitlePage from "../components/titlePage";
import { IQuestionProps } from "../interface/interface";
import { getData } from "../helper/utils";
import ButtonNext from "../components/buttonNext";
import Title from "../components/title";
import { useEffect } from "react";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";

const Interrogatoire = () => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);


  // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"interrogatoire"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
  }, [])

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="INTERROGATOIRE PATIENT"></TitlePage>
      <Title
        icon="icon_interrogatoire"
        title="Vous avez procédé à l'interrogatoire de votre patient. Découvrez les
          questions qui ont été posées et les réponses apportées par le patient."
      />

      <div className="app-questions">
        {patient?.interrogatoire.map(
          (question: IQuestionProps, index: number) => {
            return (
              <div className="question" key={index}>
                <Question
                  response={question.response}
                  info={question.info}
                  title={question.title}
                  index={index}
                />
              </div>
            );
          }
        )}
      </div>
      <ButtonNext classname="desktopMargin" path="examen_clinique" />
    </>
  );
};

export default Interrogatoire;


