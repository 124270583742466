export const PATH ="https://www.changepain.fr/";
export const API_URL ="https://changepain.jake-digital.com/api/send.php";
export const API_URL_PROD= "https://cas-patients.changepain.fr/api/send.php";
export const CARD_MENU = [
  {
    pic:"/images/home/perso_1.png",
    title:"Un patient aux douleurs thoraciques persistantes",
    info:"Par le Pr Christophe Doddoli<br>Hôpital Nord, Marseille",
    link:"/patient/1/dossier",
  },
  {
    pic:"/images/home/perso_2.png",
    title:"Une dynamique septuagénaire",
    info:"Par le Dr Anne Lassaux<br>Hôpital Rothschild, Paris",
    link:"/patient/2/dossier",
  },
  {
    pic:"/images/home/perso_3.png",
    title:"Des picotements et fourmillements quotidiens, insupportables",
    info:"Par le Pr Nicolas Girard<br>Institut Curie, Paris",
    link:"/patient/3/dossier",
  },
  {
    pic:"/images/home/perso_4.png",
    title:"Un cinquantenaire <br>en arrêt maladie",
    info:"Par le Pr Serge Perrot<br>Hôpital Cochin, Paris",
    link:"/patient/4/dossier",
  },
];
export const _code=["M-NPR-FR-11-21-0004 - Janvier 2022", "M-NPR-FR-11-21-0005 - Janvier 2022","M-NPR-FR-11-21-0007 - Janvier 2022","M-NPR-FR-11-21-0006 - Janvier 2022","M-NPR-FR-11-21-0001 - Janvier 2022"]
export const KEY ="3a5fb498-5698-11ec-bf63-0242ac130002"
export const PATIENTS = {
  patient_1: {
    // home patient
    profil: {
      title:"Homme de 68 ans. 1m72. 78 kg. IMC : 26,3.",
      description:
       "Retraité vivant avec sa femme. Ancien vendeur magasinier. Très actif, il fait beaucoup de bricolages chez lui et s'occupe de son jardin de façon quasi quotidienne.",
    },
    histoire: {
      title:"Histoire de la maladie",
      icon:"histoire",
      paragraph:
       "Patient de 68 ans chez qui a été découvert un cancer pulmonaire primitif droit de stade localement avancé. Le bilan pré thérapeutique a donc mis en évidence une tumeur du lobe pulmonaire supérieur droit de stade cT2N2 (uni-site) M0. Son dossier a été discuté en RCP et il est décidé de lui proposer une chirurgie première de type lobectomie supérieure droite associée à un curage par une thoracotomie latérale. Il est opéré le 11 janvier 2021. La chirurgie prévue est effectuée.<br/><br/>Les suites opératoires sont relativement simples. Il est pris en charge dans un contexte de RAAC (Réhabilitation Améliorée Après Chirurgie). Il sort à J5 à son domicile avec des séances de kinéthérapie, des pansements et la prescription d'antalgiques de palier 3 par voie orale. Il n'est pas retrouvé d'atteinte ganglionnaire sur la pièce opératoire et il est ainsi décidé une surveillance simple sans traitement adjuvant. Les morphiniques ont été arrêtées à J15 avec un relais par doliprane.<br/><br/>Des douleurs persistantes et assez invalidantes conduisent à la prescription d'antiépileptique 50 X 2 puis 75 X2. Les doses ont été progressivement augmentées jusqu'à 600mg avec des douleurs toujours présentes au 6ème mois post-opératoire.",
    },
    consultation: {
      title:"Motif de consultation",
      icon:"consultation",
      paragraph:
       "Douleur thoracique persistante dans les suites d'une chirurgie pulmonaire.",
    },
    hygiene: {
      title:"Hygiène de vie",
      icon:"hygiene",
      paragraph:
       "Bonne hygiène alimentaire. Limitation dans les activités physiques quotidiennes habituelles en raison des douleurs thoraciques séquellaires. Terrain anxieux.",
    },
    antecedant: {
      title:"Antécédents",
      icon:"antecedent",
      paragraph:
       "Il a une hypertension artérielle (HTA) traitée depuis 5 ans et bien équilibrée. Sa consommation d'alcool est occasionnelle. Il fume 60 PA (paquets-années) et est sevré depuis 2019. Il est de nature anxieuse.",
    },
    traitement: {
      title:"Traitements en cours",
      icon:"traitement",
      paragraph:
       "Il prend un antihypertenseur à 5mg par jour, un antiépileptique à 75mg 4 fois le matin, 4 fois le soir, un antalgique de pallier 1, 1000mg à la demande jusqu'à 3 par jour.",
    },
    examen: {
      title:"Examens complémentaires",
      icon:"examen",
      paragraph:
       "Radiographie thoracique de face normale.<br>Tomodensitométrie thoraco-abdomino-pelvienne sans et avec injection d'iode au 6ème mois post-opératoire normale.",
    },
    synthese: {
      devenir: {
        title:"Devenir du patient",
        paragraph:
         "Patient à 6 mois d'une chirurgie thoracique pour cancer primitif pulmonaire suivi d'une chimiothérapie adjuvante qui s'est terminé. Etat de rémission à la date de la consultation (imagerie normale - pas d'intêret des biomarqueurs dans le cancer du poumon). La guérison n'est cependant considérée comme acquise qu'à 5 ans.",
      },

      avis: {
        title:"Avis de l'expert",
        paragraph:
         "Le patient de 68 ans souffre de douleurs neuropathiques intercostales chroniques post opératoires qui sont rapportées avec un taux d'incident maximal de 30% dans la littérature. Son traitement antalgique actuel n'est pas suffisant pour lui permettre de retrouver une qualité de vie satisfaisante au quotidien avec notamment la possibilité de reprendre son activité de bricolage.<br/><br/>Il est nécessaire de ne pas laisser le patient sans solution ni objectif d'amélioration à venir. Il faut donc lui proposer une prise en charge en milieu spécialisé avec une stratégie multimodale pouvant inclure une thérapeutique topique associée à des thérapeutiques par voie orale.<br/><br/>La prise en charge globale et multidisciplinaire reste primordiale avec notamment la prise en compte du terrain anxieux qui peut majorer ses douleurs neuropathiques périphériques.",
      },
    },
    // interrogatoire patient
    interrogatoire: [
      {
        title:"Comment pouvez-vous décrire votre douleur ?",
        response:
         "Le patient ressent des douleurs de type brûlures avec des décharges électriques et une sensation de froid par moments.",
        info:"Cette réponse pourra être utile pour l'orientation diagnostique.",
      },

      {
        title:"Est-ce que les douleurs handicapent votre quotidien ?",
        response:
         "La vie sociale du patient est perturbée, il y a une diminution des activités habituelles et une répercussion sur le moral (l'hypothèse d'une dépression est à émettre).",
        info:"Cette question permet de dépister la nécessité d'une prise en charge socio psychologique associée <i>(Chronic pain-associated depression: antecedent or consequence of chronic pain? A review. DA Fishbain, R Cutler, HL Rosomoff… - … Clinical journal of pain, 1997 - journals.lww.com / Chronic pain and depression. A Surah, G Baranidharan, S Morley - … Anaesthesia, Critical Care and Pain, 2014 - bjaed.org)</i>",
      },
      {
        title:"La douleur est-elle insomniante ?",
        response:
         "Elle ne l'est pas de façon systématique mais le sommeil est souvent entrecoupé de réveils.",
        info:"La recherche d'une insomnie peut diminuer l'atteinte de la douleur <i>(Les troubles du sommeil chez les patients souffrant de douleur chronique. M Racine, M Choinière - 2010 - psychaanalyse.com / ﻿Approches pharmacologiques de la douleur et du sommeil P Beaulieu - Douleur et analgésie, 2003 - Springer)</i>",
      },
      {
        title:
         "Avez-vous des effets secondaires du traitement antalgique actuel ?",
        response:
         "Le patient ne présente pas d'effets secondaires des traitements qu'on lui a prescrit jusqu'à présent pour la prise en charge de ses douleurs.",
        info:"Cette partie sera une aide à l'amélioration du traitement mais ne sera pertinente qu'une fois que le diagnostic de certitude sera posé.",
      },
      {
        title:
         "La douleur est-elle associée à d'autres symptômes ?",
        response:
         "Il ne ressent pas d'autre symptôme associé en dehors parfois de réveils à priori en rapport avec sa douleur.",
        info:"Cette partie sera une aide à l'amélioration du traitement mais ne sera pertinente qu'une fois que le diagnostic de certitude sera posé.",
      },
      {
        title:
         "La douleur est-elle atténuée par moment ?",
        response:
         "La douleur est variable dans la journée sans réel facteur déclenchant.",
        info:"Cette partie sera une aide à l'amélioration du traitement mais ne sera pertinente qu'une fois que le diagnostic de certitude sera posé.",
      }
    ],
    // quiz patient
    quiz_1: [
      {
        answsers: [
          {
            title:"Évaluation du DN4",
            choice:"1",
            feedback:
             "Ce questionnaire permet d'investiguer sur un éventuel caractère neuropathique des douleurs.",
            reference:
             "<i>Investigating the validity of the DN4 in a consecutive population of patients with chronic pain. H Timmerman, MAH Steegers, FJPM Huygen… - PLoS …, 2017 - journals.plos.org</i>",
          },
          {
            title:"Examen physique de la région opératoire",
            choice:"1",
            feedback:"Peut éliminer une cause anatomique aux douleurs",
            reference:"",
          },
          {
            title:"Auscultation thoracique",
            choice:"0",
            feedback:"Dans le cadre du diagnostic différentiel",
            reference:"",
          },
          {
            title:"Prise de la tension artérielle TA",
            choice:"0",
            feedback:"Éventuel retentissement cardiovasculaire",
            reference:"",
          },
          {
            title:"Prise de la fréquence cardiaque",
            choice:"0",
            feedback:"Éventuel retentissement cardiovasculaire",
            reference:"",
          },
          {
            title:"Examen neurologique",
            choice:"1",
            feedback:"Caractérisation de la plainte exprimée",
            reference:"",
          },
        ],
      },
    ],

    rapport: {
      title:"Voici le rapport d'examen clinique :",
      paragraph: `<p>Le score du DN4 est à 5.</p><p>L'EVA (échelle analogique visuelle) est entre 6 et 9 selon les moments de la journée.</p> 
      <p>Le patient ressent des sensations de brûlure, des décharges électriques et surtout une sensation de froid douloureux. </p>
      <p>L'inspection retrouve une cicatrice sans anomalie. Au toucher le patient ressent une hypoesthésie et le frottement engendre une allodynie. L'ausculation est strictement normale. Les aires ganglionnaires cervicales et sus-claviculaires sont libres.</p>`,
    },
    rapport_complementaire: {
      title:"Voici le compte rendu des examens réalisés",
      paragraph: `Le bilan hépatique est normal. La TDM TAP sans et avec injection d'iode ne retrouve aucune lésion suspecte de récidive au niveau thoracique ni abdomimo-pelvienne d'une part et aucune anomalie pariétale dans la région de la thoracotomie (fracture de côte).`,
    },

    quiz_2: [
      {
        answsers: [
          {
            title:"NFS Plaquettes",
            choice:"0",
            feedback:
             "Pas nécessaire en pratique mais peut éliminer une infection",
            reference:
             "",
          },
          {
            title:"Ionogramme sanguin",
            choice:"0",
            feedback:"Pas de réel intérêt mais pourrait se faire à titre systématique",
            reference:"",
          },
          {
            title:"CRP, VS",
            choice:"0",
            feedback:"Pas nécessaire en pratique mais peut éliminer un syndrome inflammatoire",
            reference:"",
          },
          {
            title:"Urée créatinine",
            choice:"0",
            feedback:"Pas nécessaire en pratique mais peut être utile pour évaluer la toxicité médicamenteuse lors d'une nouvelle prescription",
            reference:"",
          },
          {
            title:"Bilan hépathique",
            choice:"1",
            feedback:"Toxicité médicamenteuse en rapport avec les antalgiques que prend le patient",
            reference:"",
          },
          {
            title:"Marqueurs tumoraux (ACE, NSE)",
            choice:"0",
            feedback:"Aucune valeur diagnostique",
            reference:"",
          },
          {
            title:"Radiographie thoracique standard de face",
            choice:"0",
            feedback:"Moins fiable que le scanner pour éliminer une pathologie pleuro-pulmonaire sous-jacente",
            reference:"",
          },
          {
            title:"TDM TAP sans et avec injection d'iode",
            choice:"1",
            feedback:"Cet examen permet d'éliminer une pathologie pleuro-pulmonaire sous-jacente",
            reference:"",
          },
        ],
        categorie : [
          {name :"Biologie"},
          {name :"Imagerie"}
        ]
      },
    ],
    rapport_2: {
      title:"Voici le compte-rendu des examens réalisés :",
      paragraph: `<p>Le blian hépathique est normal.</p> 
      <p>La TDM TAP sans et avec injection d'iode ne retrouve aucune lésion suspecte de récidive au niveau thoracique ni abdomimo-pelvienne d'une part et aucune anomalie pariétale dans la région de la thoracotomie (fracture de côte).</p>`
    },

    quiz_3: [
      {
        answsers: [
          {
            title:"Douleurs neuropathiques périphériques post-opératoires",
            choice:"1",
            feedback:
             "",
            reference:
             "",
          },
          {
            title:"Angine de poitrine",
            choice:"0",
            feedback:"Diagnostic différentiel moins probable car persistant, sans caractère de constriction et chronique",
            reference:"",
          },
          {
            title:"Pleurésie",
            choice:"0",
            feedback:"Diagnostic différentiel : douleur permanente associée à une dyspnée et une toux",
            reference:"",
          },
          {
            title:"Embolie pulmonaire",
            choice:"0",
            feedback:"Diagnostic différentiel : rarement douloureux, d'installation brutale avec une dyspnée",
            reference:"",
          },
          {
            title:"Fracture de côte",
            choice:"0",
            feedback:"Diagnostic différentiel : peut être associée à la douleur neuropathique, mais visible au scanner",
            reference:"",
          },
          {
            title:"Reflux gastro-oesophagien",
            choice:"0",
            feedback:"Diagnostic différentiel : rythmé par les repas et souvent majoré par la position allongée",
            reference:"",
          },
        ],
      },
    ],
    quiz_4: [
      {
        answsers: [
          {
            title:"Caractéristiques de la douleur (brulure, décharge électrique)",
            choice:"1",
            feedback:
             "Diagnostic positif de DNP",
            reference:
             "<i>Pluijms WA et al. Chronic post-thoracotomy pain: a retrospective study. Acta Anaesthesiol Scand 2006;50:804–8.</i>",
          },
          {
            title:"Examen physique (allodynie)",
            choice:"1",
            feedback:"Diagnostic positif de DNP",
            reference:"<i>Jensen TS, Finnerup NB. Allodynia and hyperalgesia in neuropathic pain: clinical manifestations and mechanisms. Lancet Neurol. 2014 Sep;13(9):924-35</i>",
          },
          {
            title:"Scanner thoracique normal",
            choice:"0",
            feedback:"Cet examen permet d'éliminer une pathologie pleuro-pulmonaire sous-jacente",
            reference:"",
          },
          {
            title:"La douleur est isolée",
            choice:"0",
            feedback:"Diagnostic différentiel",
            reference:"",
          },
          {
            title:"Pas d'amaigrissement associé",
            choice:"0",
            feedback:"Diagnostic différentiel",
            reference:"",
          },
          {
            title:"Biologie normale",
            choice:"0",
            feedback:"Diagnostic différentiel",
            reference:"",
          },
        ],
      },
    ],

    quiz_5: [
      {
        answsers: [
          {
            title:"Prise en charge psychologique",
            choice:"1",
            feedback:
             "Évaluer l'existence d'un syndrome dépressif",
            reference:
             "<i>Postoperative pain and subsequent PTSD-related symptoms in patients undergoing lung resection for suspected cancer. Morgana Jeantieu 1, Françoise Gaillat, François Antonini, Elie Azoulay, Claude Martin, Pascal Thomas, Marc Leone. J Thorac Oncol. 2014 Mar;9(3):362-9</i>",
          },
          {
            title:"Hospitalisation dans une unité anti-douleur",
            choice:"1",
            feedback:"Évaluation globale et pluridisciplinaire de la douleur. Initiation de traitements non disponibles en ville.",
            reference:"",
          },
          {
            title:"Modification du traitement antalgique",
            choice:"1",
            feedback:"Optimiser le traitement",
            reference:"",
          },
          {
            title:"Prescription d'Anti-Inflammatoires Non-Stéroïdiens (AINS)",
            choice:"0",
            feedback:"Pas d'intérêt dans ce contexte non inflammatoire",
            reference:"",
          },
          {
            title:"RESC",
            choice:"0",
            feedback:"Pas à ce moment de la prise en charge",
            reference:"",
          },
          {
            title:"Hospitalisation en centre de rééducation",
            choice:"0",
            feedback:"Aucune indication",
            reference:"",
          },
        ],
      },
    ],
  },

  patient_2: {
    // home patient
    profil: {
      title:"Femme de 77 ans. 1m50. 45 kg. IMC : 20.",
      description:
       "",
    },
    histoire: {
      title:"Histoire de la maladie",
      icon:"histoire",
      paragraph:
       "Il y a 8 ans, son mari a eu la grippe et même si elle-même n'avait pas été malade, elle s'était sentie très fatiguée. Tellement fatiguée, qu'elle a failli annuler sa séance de piscine hebdomadaire avec sa fille, surtout qu'elle ressentait depuis la veille une brûlure dans le milieu du dos. Malgré tout, elle s'était laissée convaincre par sa fille d'aller nager sous le prétexte que ce serait bon pour ses douleurs de dos et que cela lui remettrait de l'énergie en douceur.<br/><br/>Arrivées sur place, sa fille et le maître nageur ont immédiatement repéré un placard rouge avec des boutons entre les omoplates de madame. Elles sont reparties directement chez son médecin traitant. Ce dernier a posé le diagnostic de zona thoracique et prescrit : un antiviral pendant 7 jours, un anti-épileptique de deuxième génération, un antalgique de pallier 1 et des soins locaux.",
    },
    consultation: {
      title:"Motif de consultation",
      icon:"consultation",
      paragraph:
       "Venue en consultation d'une pimpante et dynamique dame de 77 ans pour des douleurs ressenties dans le dos, à droite entre les omoplates au niveau de la 6ème vertèbre thoracique, présentes depuis 8 ans. Il s'agit de douleurs permanentes de type brûlures, picotements, foumillements avec aussi des crises de décharges électriques très intenses, de fréquence variable, allant de 1 fois par semaine à 3 fois dans une même journée.<br/><br/>Elle a remarqué que ces crises douloureuses sont plus fréquentes si elle est fatiguée ou contrariée. De plus, dans ces circonstances, elle a une zone douloureuse suplémentaire devant, sous le sein droit, près du sternum, de la taille d'une demie paume de main, avec seulement des brûlures assez sourdes.  ",
    },
    hygiene: {
      title:"Hygiène de vie",
      icon:"hygiene",
      paragraph:
       "Elle est dynamique, sort marcher tous les jours et s'occupe de sa famille.",
    },
    antecedant: {
      title:"Antécédents",
      icon:"antecedent",
      paragraph:
       `Chirurgicaux : amygdalectomie (à 4 ans), appendicectomie (à 7 ans), cure de prolapsus (à 34 ans), lifting cervico-facial (68 ans), prothèse totale de hanche droite (à 69 ans), prothèse totale de hanche gauche (à 70 ans).
       <br/><br/>Médicaux : 3 enfants en bonne santé, un kyste du pancréas suivi depuis ses 61 ans, une scoliose lombaire, des douleurs lombaires épisodiques, un zona thoracique (à 69 ans).
       `,
    },
    traitement: {
      title:"Traitements en cours",
      icon:"traitement",
      paragraph:
       `Un antiviral (2 comprimés par jour soit 800 milligrammes par jour).<br/>
       Un antiépileptique de 2ème génération.<br/>
       Un antalgique topique.<br/><br/>
       Au cours des 8 années, différents traitements ont été testés : un autre antiépileptique, des antidépresseurs, deux antalgiques de pallier 2 et un antalgique de pallier 1 sans bénéfice supérieur au traitement actuel. Ces médicaments ont été arrêtés en raison de leurs effets secondaires : sédation, vertiges, malaise, nausées...
       `,
    },
    examen: {
      title:"Examens complémentaires",
      icon:"examen",
      paragraph:
       "Pas d'examen complémentaire.",
    },
    synthese: {
      devenir: {
        title:"Devenir du patient",
        paragraph:
         `Cette patiente a eu une application d'un antalgique topique. Elle a dormi paisiblement pendant la durée de l'application. Elle a eu une journée sans aucune douleur juste après l'application, puis les douleurs sont revenues. Il y a eu une diminution durable des douleurs de fond. Elle donne des scores de douleur pour l'échelle numérique  EN= 3 à 4/10 et pour l'échelle visuelle simple EVS= 2/4 soit des douleurs moyennes. Elle n'a pas modifié son traitement antiépileptique.<br/><br/>Les crises douloureuses avec décharges électriques sont très rares, en moyenne 2 fois par mois. Elle est satisfaite et ne souhaite pas une deuxième application. Elle pourra solliciter l'équipe soignante dans le cas où ses douleurs seraient plus importantes pour réévaluer la nécessité d'une autre pose de patch antalgique. 
         `,
      },

      avis: {
        title:"Avis de l'expert",
        paragraph:
         `<p>La consultation de cette charmante patiente de 77 ans est instructive à divers titres. Alors qu’elle est en bonne santé et dynamique, suite à un zona thoracique survenu il y a 8 ans, elle souffre quotidiennement de douleurs fortes s'exprimant sous forme de brûlures et de décharges électriques, qui sont représentatives des douleurs neuropathiques post-zostériennes (DN4).<br/><br/>Pourtant, le diagnostic initial du zona a été rapide, la mise en route du traitement immédiate, conforme aux recommandations, bien suivi par la patiente, il n’y a pas eu de complication locale et la cicatrisation s’est bien déroulée.<br/><br/>Différents traitements de ces douleurs ont été testés et son traitement actuel, qui associe un antiépileptique de 2ème génération et un antalgique topique, atténue les douleurs et lui a permis de rester très active et d’avoir une bonne qualité de sommeil malgré ses douleurs fortes. L’application d’un antalgique topique a amélioré les douleurs et pourrait être renouvelé si nécessaire.</p>
         <br/><p>Pour toute information sur les traitements relatifs à la prise en charge des DNP, vous pouvez vous réferer aux recommandations de la SFETD 2020 (Société Française d'Etude et de Traitement de la Douleur).</p>`,
      },
    },
    // interrogatoire patient
    interrogatoire: [
      {
        title:"Depuis quand durent les douleurs ?",
        response:
         "Les douleurs ont commencé lors du zona dans le dos (thoracique) et elles durent depuis.",
        info:"Il est important de rattacher les douleurs actuelles à cet évênement précis dans ce cas, mais le diagnostic peut être plus difficle si le zona initial n'a pas éte identifié ou si le patient a oublié l'évènement initial en raison de troubles cognitifs. <i>(Meister W et al. A prognostic score for postherpetic neuralgia in ambulatory patients. Infection. 1998;26(6):359-63. / Oster G et al. Pain, medication use, and health-related quality of life in older persons with postherpetic neuralgia: results from a population-based survey. J Pain. 2005;6(6):356-63.)</i> ",
      },

      {
        title:"Quel genre de douleurs avez-vous ?",
        response:
         "Les douleurs ressenties sont des brûlures, des picotements, des fourmillements, avec en plus des crises de décharges électriques.",
        info:"Les caractères de ces douleurs sont particuliers. Ils ne sont pas évocateurs de douleurs inflammatoires associant douleurs-rougeur-chaleur ou de douleurs mécaniques provoquées par une activité physique, ou soulagées par cette dernière.",
      },
      {
        title:"Quelle est l'intensité des douleurs actuelles au quotidien ?",
        response:
         "Concernant les douleurs de fond quotidiennes : la patiente donne un score pour l'échelle numérique (EN) de 7 à 8/10 ou pour l'échelle visuelle simple (EVS) de 3/4, soit des douleurs fortes ; pour les crises électriques, le score est de 10/10 pour l'EN et 4/4 pour l'EVS, soit des douleurs très fortes.",
        info:"Il est très utile de quantifier l'intensité des douleurs afin d'avoir des valeurs initiales et de suivi permettant d'évaluer l'efficacité du ou des traitements effectués. L'évaluation des douleurs se fait par des échelles validées : l'échelle numérique (EN) et l'échelle verbale simple (EVS). <i>(McDonald EM et al. Antivirals for management of herpes zoster including ophthalmicus: a systematic review of high quality randomized controlled trials. Antivir Ther. 2012;17(2):255-64.)</i>",
      },
      {
        title:
         "Est-ce qu'elles vous empêchent de dormir ?",
        response:
         "Les douleurs ne l'empêchent pas de dormir car son mari lui met un morceau d'emplâtre d'anesthésique local tous les soirs, et lui enlève le matin.",
        info:"Les troubles du sommeil dans les douleurs chroniques sont un facteur de risque pour la survenue d'anxiété et de dépression. <i>(Meister W et al. A prognostic score for postherpetic neuralgia in ambulatory patients. Infection. 1998;26(6):359-63)</i>",
      },
      {
        title:
         "Est-ce qu'elles vous rendent triste ou fatiguée ou vous bloquent dans votre vie au quotidien ?",
        response:
         "La patiente indique que les douleurs ne sont pas moins fortes si elle se repose et qu'elle continue sa vie en étant active. En revanche, si elle est stressée, elle ne les supporte plus et elle s'énerve contre son entourage.",
        info:"Malgré ses douleurs, cette patiente continue à fonctionner  normalement dans sa vie mais ces douleurs chroniques quotidiennes l'exposent à un risque accru de dépression et d'anxiété. Il ne faut pas hésiter à faire une échelle HAD. <i>(Attal N et al. The specific disease burden of neuropathic pain: results of a French nationwide survey. Pain. 2011;152(12):2836-43 / Echelle HAD Hospital Anxiety and dépression scale qui est un instrument qui permet de dépister les troubles anxieux et dépressifs. Il s'agit d'un questionnaire avec 14 questions et 3 possibilités de réponse pour chaque question.)</i>",
      },
      {
        title:
         "Est-ce que vous avez eu d'autres problèmes de santé importants depuis ce zona ?",
        response:
         "La patiente a été opérée pour ses hanches et cela s'est très bien passé.",
        info:"Par chance, elle n'a pas eu de problème de santé particulier depuis son zona or il existe un risque accru de comorbidité médicale avec les douleurs chroniques. <i>(Dworkin RH, Malone DC, Panarites CJ, et al. Impact of postherpetic neuralgia and painful diabetic peripheral neuropathy on health care costs. J Pain. 2010;11(4):360-8.)</i>",
      },
      {
        title:
         "Au départ, est-ce que le diagnostic et le traitement ont été rapides ?",
        response:
         "Oui, son médecin traitant a tout de suite posé le diagnostic et commencé le traitement.",
        info:"La prise en charge thérapeutique rapide et un traitement initial bien conduit sont importants pour diminuer le risque de douleurs ultérieures. <i>(McDonald EM et al. Antivirals for management of herpes zoster including ophthalmicus: a systematic review of high quality randomized controlled trials. Antivir Ther. 2012;17(2):255-64.)</i>",
      },
      {
        title:
         "Est-ce que vous avez eu beaucoup de boutons ? Est-ce que la zone était étendue ?",
        response:
         "Selon le médecin traitant de la patiente et sa fille, le zona était étendu sur le thorax en arrière et il y avait beaucoup de boutons.",
        info:"Il n'y a pas de facteur de gravité local initial mais un zona est un facteur de risque pour la survenue de complications locales infectieuses ainsi que de douleurs persistantes (estimé entre 20 et 60% suivant les études en raison de son âge supérieur à 60 ans). <i>(Oster G et al. Pain, medication use, and health-related quality of life in older persons with postherpetic neuralgia: results from a population-based survey. J Pain. 2005;6(6):356-63.)</i>",
      }
      ,
      {
        title:
         "Est-ce que les douleurs étaient fortes au départ ?",
        response:
         "Les douleurs ressenties par la patiente étaient très fortes dès le début. ",
        info:"Si cette situation est fréquente, l'intensité initiale des douleurs est un facteur de risque pour la persistance des douleurs après cicatrisation. <i>(Dworkin RH, Partenoy RK. Pain and its persistence in herpes zoster. Pain. 1996 oct;67(2-3):241-51.)</i>",
      }
      ,
      {
        title:
         "Est-ce que la cicatrisation a été rapide ?",
        response:
         "La patiente indique qu'en 10 jours la peau était cicatrisée. Une infirmière est venue tous les jours à domicile pour des soins locaux et suivre l'évolution de la cicatrisation. Il n'y a pas eu de surinfection. Mais les douleurs sont restées.",
        info:"L'évolution rapide vers la guérison cutanée, sans surinfection locale  étaient des éléments positifs. Les soins infirmiers étaient justifiés car cette zone n'était pas accessible pour la patiente.<i> (Meister W et al. A prognostic score for postherpetic neuralgia in ambulatory patients. Infection. 1998;26(6):359-63.)</i>",
      }
    ],
    // quiz patient
    quiz_1: [
      {
        answsers: [
          {
            title:"Observation cutanée",
            choice:"1",
            feedback:
             "Cet examen permet d'éliminer une lésion d'une autre nature : eczéma, cancer cutané, lésions de grattage, séquelle de brûlure…",
            reference:
             "",
          },
          {
            title:"Evaluation de la douleur avec une échelle numérique (EN)",
            choice:"1",
            feedback:"Il s'agit d'une auto-évaluation de l'intensité de la douleur par la patiente et d'une mesure de référence pour le suivi de prise en charge.",
            reference:"<i>HAS - Liste des échelles acceptées pour mesurer la douleur 26-05-2020</i>",
          },
          {
            title:"Questionnaire DN4",
            choice:"1",
            feedback:"Le résultat de ce questionnaire sera crucial dans la prise de décision thérapeutique.",
            reference:"<i>Bouhassira D et al. Development and validation of the Neuropathic Pain Symptom Inventory. Pain. 2004;108(3):248-57.</i>",
          },
          {
            title:"IRM du rachis dorsal",
            choice:"0",
            feedback:"Cet examen n'apportera pas d'élément diagnostique ou thérapeutique.",
            reference:"",
          },
          {
            title:"Biopsies cutanées",
            choice:"0",
            feedback:"Ces examens n'apporteront pas d'élément diagnostique ou thérapeutique et aggraveront potentiellement les douleurs.",
            reference:"",
          }
        ],
      },
    ],

    rapport: {
      title:"Voici le rapport d'examen clinique :",
      paragraph: `<p>La zone de peau douloureuse est à droite entre le rachis dorsal et le bord interne de l'omoplate, grande comme une paume de main. La peau a un aspect un peu irrégulier, comme froissée. Elle est plus fine et a une pigmentation plus claire que celle du reste du dos. Il n'y a pas de bouton ou d'aspéritée cutanée. La palpation très douce en périphérie permet de délimiter facilement la zone douloureuse au centimètre près.</p> 
      <p>Pour éviter les douleurs exacerbées par le frottement, elle m'explique qu'elle ne porte plus de soutien gorge mais un débardeur très souple qui la protège du contact avec ses autres vêtements. 
      </p><p>Quand les douleurs du dos sont très intenses ou si elle est fatiguée ou contrariée, elle peut avoir une douleur sur la partie antérieure du thorax, sous le sein droit. A cet endroit, la peau est parfaitement saine et indolore lors de l'examen clinique ce jour.</p>`,
    },
    rapport_complementaire: {
      title:"Voici le compte rendu des examens réalisés",
      paragraph: `Le bilan hépatique est normal. La TDM TAP sans et avec injection d'iode ne retrouve aucune lésion suspecte de récidive au niveau thoracique ni abdomimo-pelvienne d'une part et aucune anomalie pariétale dans la région de la thoracotomie (fracture de côte).`,
    },

    quiz_2: [
      {
        answsers: [{
          title:"Douleurs neuropathiques périphériques post-opératoires",
          choice:"1",
          feedback:
           "",
          reference:
           "",
        }],
        categorie : [
          {name :"Biologie"},
          {name :"Imagerie"}
        ]
      },
    ],
    rapport_2: {
      title:"",
      paragraph: ``
    },

    quiz_3: [
      {
        answsers: [
          {
            title:"Douleurs neuropathiques post-zostériennes",
            choice:"1",
            feedback:
             "",
            reference:
             "",
          },
          {
            title:"Séquelles douloureuses d'une fracture tassement de la 6ème vertèbre thoracique",
            choice:"0",
            feedback:"Il n'y a pas de notion de chute ou de traumatisme lors de la survenue des douleurs initiales et les douleurs ont toujours été unilatérales.",
            reference:"",
          },
          {
            title:"Arthrose rachidienne ",
            choice:"0",
            feedback:"Les douleurs seraient plus diffuses sur l'ensemble du rachis avec des caractères mécaniques. ",
            reference:"",
          },
          {
            title:"Un cancer de la peau au niveau du dos",
            choice:"0",
            feedback:"La zone de peau concernée n'a pas de naevus, ni de pigmentation foncée. Il n'y a pas d'épaississement cutané, de croûte ou d'ulcération. ",
            reference:"<i>HAS-Mélanome cutané: la détection précoce est essentielle 11 février 2020</i>",
          }
        ],
      },
    ],
    quiz_4: [
      {
        answsers: [
          {
            title:"Le diagnostic initial de zona",
            choice:"1",
            feedback:
             "La fréquence des douleurs post-zostériennes est de 10% si la patiente a un zona du thorax.",
            reference:
             "<i>Hope-Simpson RE. Postherpetic neuralgia. J R Coll Gen Pract. 1975 Aug;25(157):571-5.</i>",
          },
          {
            title:"L'âge de survenue est de 69 ans",
            choice:"1",
            feedback:"La fréquence des douleurs post zostériennes est supérieure après 50 ans et chez les femmes. De plus, pour la tranche d'âge 60-69 ans, la fréquence des névralgies post zostériennes, toutes localisations confondues, est de 20 %.",
            reference:"<i>Hope-Simpson RE. Postherpetic neuralgia. J R Coll Gen Pract. 1975 Aug;25(157):571-5.</i>",
          },
          {
            title:"Le vitiligo",
            choice:"0",
            feedback:"Le vitiligo peut donner des démangeaisons avant que la zone dépigmentée s'installe mais pas de douleurs neuropathiques. De plus, la localisation unique ne correspond pas aux sites habituels de la dépigmentation du vitiligo : les mains, les bras, le visage, les pieds. ",
            reference:"<i>Vitiligo une dermatose bénigne dont l'impact psychologique est parfois considérable. INSERM 14/08/2017. <a style='text-decoration:underline' title='Vitiligo' href='https://www.inserm.fr/dossier/vitiligo/' target='_blank'>https://www.inserm.fr/dossier/vitiligo/</a></i>",
          },
          {
            title:"L'eczema chronique",
            choice:"0",
            feedback:"Il n'y a pas de lésion cutanée évocatrice d'un eczéma.",
            reference:"",
          }
        ],
      },
    ],

    quiz_5: [
      {
        answsers: [
          {
            title:"Envoi de la patiente vers une Consultation Douleurs Chroniques pour un traitement plus spécifique comme la prescription d'un antalgique topique et/ou de la TENS (Stimulation électrique transcutanée)",
            choice:"1",
            feedback:
             "La patiente pourra bénéficier d'un test thérapeutique utilisant la TENS encadrant la zone douloureuse, d'une initiation à l'utilisation de cette technique qu'elle pourra utiliser ensuite à domicile. Le traitement topique antalgique est facile à mettre en œuvre chez une personne âgée. Il se fera au sein même de l'Unité Douleur en ambulatoire.                                                                                                                                                                                                                                                                         ",
            reference:
             "<i>Derry S, Rice AS C, Cole P, Tan T, Moore RA. Topical capsaicin (high concentration) for chronic neuropathic pain in adults. Cochrane Database of Systematic Reviews 2013, Issue 2. Art. No.: CD007393. DOI:10.1002/14651858.CD007393.pub3</i>",
          },
          {
            title:"Vaccination antivariolique",
            choice:"1",
            feedback:"La vaccination est effectuée pour protéger la patiente d'un éventuel deuxième zona.",
            reference:"<i>Oxman MN, Levin MJ, Johnson Gr et al. A vaccine to prevent herpes zoster and postherpetic neuralgia in older adults. New Engl J Med 2005; 352: 2271-84</i>",
          },
          {
            title:"Acupuncture",
            choice:"1",
            feedback:"Différents protocoles existent et agissent sur l'intensité douloureuse.",
            reference:"<i>Coyle ME, Liang H, Wang K, Zhang AL, Guo X, Lu C, Xue CC. Acupuncture plus moxibustion for herpes zoster : A systematic review and meta-analysis of randomized controlled trials. Dermatol Ther. 2017 Mar 24.</i>",
          },
          {
            title:"Traitement au long cours par un antalgique de palier 3 (selon l'OMS)",
            choice:"0",
            feedback:"Le traitement n'est pas adapté au long court et peut entrainer une sensibilisation centrale dommageable.",
            reference:"<i>Moisset X, Trouvin AP, Tran VT, et al (2016) Use of strong opioids in chronic non-cancer pain in adults. Evidence-based recommendations from the French Society for the Study and Treatment of Pain. Presse Med Paris Fr 45:447–62. https://doi.org/10.1016/j.lpm.2016.02.014 / ANSM (2019) État des lieux de la consommation des antalgiques opioïdes et leurs usages problématiques. <a style='text-decoration:underline' title='' target='_blank' href='https://ansm.sante.fr/actualites/antalgiques-opioides-lansm-publie-un-etat-des-lieux-de-la-consommation-en-france'>https://ansm.sante.fr/actualites/antalgiques-opioides-lansm-publie-un-etat-des-lieux-de-la-consommation-en-france</a>. Accessed 19 Jul 2019  /  Anekar AA. WHO Analgesic Ladder. in Stat Pearls [Internet], Treasure Island (FL): StatPearls Publishing; 2022 Jan.</i>",
          },
          {
            title:"Poursuite du traitement antiviral",
            choice:"0",
            feedback:"L'antiviral au long cours n'a pas d'indication.",
            reference:"<i>McDonald EM et al. Antivirals for management of herpes zoster including ophthalmicus: a systematic review of high quality randomized controlled trials. Antivir Ther. 2012;17(2):255-64.</i>",
          }
        ],
      },
    ],
  },
 
  patient_3: {
    // home patient
    profil: {
      title:"Homme de 66 ans. 1m74. 74 kg. IMC : 26.",
      description:
       "",
    },
    histoire: {
      title:"Histoire de la maladie",
      icon:"histoire",
      paragraph:
       "Le patient a été pris en charge pour une douleur hémithoracique, conduisant à identifier une masse pulmonaire localisée dans le lobe supérieur droit, avec envahissement de la paroi. Une biopsie a été réalisée, montrant un cancer bronchique non à petites cellules, avec expression de PD-L1 sans altération moléculaire oncogénique. Il existe une lésion invasive, du médiastin, et s'associant à des nodules pulmonaires multiples. Un traitement par chimiothérapie et immunothérapie est mis en place, dont le patient a reçu 4 cycles.",
    },
    consultation: {
      title:"Motif de consultation",
      icon:"consultation",
      paragraph:
       "Le patient présente des douleurs thoraciques, associées à des douleurs à type de fourmillement au niveau des doigts de pieds. Le traitement par morphiniques n'est pas suffisamment efficace, et le patient a des difficultés à dormir.",
    },
    hygiene: {
      title:"Hygiène de vie",
      icon:"hygiene",
      paragraph:
       "Le patient est un consommateur de tabac actif à 1 paquet par jour, depuis l'âge de 19 ans. Il a une consommation éthylique de 5 verres par jour. Il a travaillé comme conducteur de travaux.",
    },
    antecedant: {
      title:"Antécédents",
      icon:"antecedent",
      paragraph:
       "Le patient souffre d'un diabète de type II depuis 12 ans. Il n'a pas d'antécédents familiaux.",
    },
    traitement: {
      title:"Traitements en cours",
      icon:"traitement",
      paragraph:
       "Il suit un traitement par antidiabétiques oraux, antalgique de pallier 1, un antihypertenseur (inhibiteur de l'enzyme de conversion).",
    },
    examen: {
      title:"Examens complémentaires",
      icon:"examen",
      paragraph:
       "Il a réalisé un scanner thoracique abdominal et pelvien, et une tomographie par émission de positon au 18-fluorodésoxyglucose.",
    },
    synthese: {
      devenir: {
        title:"Devenir du patient",
        paragraph:
         "Mise en place des actions proposées : adaptation du traitement antalgique et du traitement local, poursuite de l'immunothérapie seule, adaptation du diabète. Si besoin, envoi du patient en structure douleur chronique pour une prise en charge adaptée de sa douleur.",
      },

      avis: {
        title:"Avis de l'expert",
        paragraph:
         "Patient atteint de cancer broncho-pulmonaire primitif avancé et métastatique, avec douleurs neuropathiques, liées à un envahissement local, à la toxicité cumulative de la chimiothérapie, sur un terrain à risque. La prévention de la douleur locale peut passer par une radiothérapie à visée symptomatique précoce; la surveillance de la neuropathie périphérique induite par la chimiothérapie est complexe et principalement clinique, mais le choix de molécules ayant un moindre risque de neuropathie peut être envisagée chez le patient diabétique.",
      },
    },
    // interrogatoire patient
    interrogatoire: [
      {
        title:"Où la douleur est-elle localisée ?",
        response:
         "Le patient ressent une douleur au thorax à droite et des douleurs dans les pieds.",
        info:"Effectivement, d'une part une douleur par envahissement tumoral de la paroi thoracique et des nerfs intercostaux, et de la plèvre, et d'autre part des dysthésies sur probable neuropathie à la chimiothérapie favorisée par le diabète.",
      },

      {
        title:"Quel est le type de douleurs ?",
        response:
         "La douleur du thorax est sourde, en coup de poignards. Le patient ressent des fourmillements aux deux pieds.",
        info:"Cette question permet de caractériser les douleurs, la réponse du patient sera utile pour l'orientation diagnostique.",
      },
      {
        title:"Quelle est la fréquence des douleurs ?",
        response:
         "La douleur du thorax à droite est permanente. Pour les fourmillements des pieds, plutôt le soir et la nuit.",
        info:"Pas de commentaire.",
      },
      {
        title:"Y a-t-il eu une aggravation avec les perfusions de chimiothérapie ?",
        response:
         "Il n'y a pas eu d'aggravation avec la chimiothérapie.",
        info:"La chimiothérapie peut entrainer des toxicités neuropathiques à l'origine de douleurs aux extrémités (pieds, mains).",
      },
      {
        title:
         "Quelle est l'intensité des douleurs ?",
        response:
         "EVA : 5/10.",
        info:"L'intensité est significative.",
      }
    ],
    // quiz patient
    quiz_1: [
      {
        answsers: [
          {
            title:"Palpation de la paroi",
            choice:"1",
            feedback:
             "Évaluer l'aggravation des douleurs à la palpation.",
            reference:"<i>Coustet B. Sémiologie médicale L'apprentissage pratique de l'examen clinique. Edition Vuibert. Juillet 2019 ISBN 978-2-311-66089-0</i>",
          },
          {
            title:"Auscultation thoracique",
            choice:"1",
            feedback:"Évaluer l'abolition du murmure vésiculaire (recherche d'une pleurésie ou condensation).",
            reference:"<i>Coustet B. Sémiologie médicale L'apprentissage pratique de l'examen clinique. Edition Vuibert. Juillet 2019 ISBN 978-2-311-66089-0</i>",
          },
          {
            title:"Examen neurologique moteur et sensitif intercostal des membres inférieurs",
            choice:"1",
            feedback:"Évaluer une potentielle perte de sensibilité ou de motricité.",
            reference:"<i>Coustet B. Sémiologie médicale L'apprentissage pratique de l'examen clinique. Edition Vuibert. Juillet 2019 ISBN 978-2-311-66089-0</i>",
          },
          {
            title:"Calendrier de suivi des douleurs",
            choice:"1",
            feedback:"Permet de préciser pour les deux localisations, les horaires et la durée.",
            reference:"<i>Coustet B. Sémiologie médicale L'apprentissage pratique de l'examen clinique. Edition Vuibert. Juillet 2019 ISBN 978-2-311-66089-0</i>",
          }
        ],
      },
    ],

    rapport: {
      title:"Voici le rapport d'examen clinique :",
      paragraph: `<p>A l'examen clinique, la palpation du thorax à droite majore les douleurs. L'auscultation montre des rales bronchiques. Il existe une perte de sensibilité épicritique des membres inférieurs. Le calendrier montre des douleurs permanentes dans l'hémithorax et des dysesthésies des membres inférieurs surtout le soir.</p>`,
    },
    rapport_complementaire: {
      title:"Voici le compte rendu des examens réalisés",
      paragraph: `Le bilan hépatique est normal. 
      ﻿La TDM TAP sans et avec injection d'iode ne retrouve aucune lésion suspecte de récidive au niveau thoracique ni abdomimo-pelvienne d'une part et aucune anomalie pariétale dans la région de la thoracotomie (fracture de côte).`,
    },

    quiz_2: [
      {
        answsers: [
          {
            title:"Biologie standard, Hba1c",
            choice:"1",
            feedback:
             "Cet examen permet de vérifier l'équilibre du diabète.",
            reference:"<i>Zoccarato M, Grisold W, Grisold A, Poretto V, Boso F, Giometto B. Paraneoplastic Neuropathies: What's New Since the 2004 Recommended Diagnostic Criteria. Front Neurol. 2021 Oct 1;12:706169.</i>",
          },
          {
            title:"Anticorps anti-neuronaux",
            choice:"1",
            feedback:"Cet examen permet de rechercher une atteinte auto-immune possiblement aggravée par l'immunothérapie.",
            reference:"<i>Zoccarato M, Grisold W, Grisold A, Poretto V, Boso F, Giometto B. Paraneoplastic Neuropathies: What's New Since the 2004 Recommended Diagnostic Criteria. Front Neurol. 2021 Oct 1;12:706169.</i>",
          },
          {
            title:"IRM cérébrale et rachidienne",
            choice:"1",
            feedback:"Cet examen est nécessaire pour rechercher des lésions métastatiques du système nerveux central.",
            reference:"<i>Zajączkowska R, Kocot-Kępska M, Leppert W, Wordliczek J. Bone Pain in Cancer Patients: Mechanisms and Current Treatment. Int J Mol Sci. 2019 Nov 30;20(23):6047.</i>",
          },
          {
            title:"Imagerie par résonance magnétique cérébrale et rachidienne",
            choice:"1",
            feedback:"Cet examen est pertinent dans le contexte oncologique pour s'assurer de l'absence de métastase du système nerveux central.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Scanner thoracique et abdomino pelvien",
            choice:"0",
            feedback:"Cet examen est déjà disponible.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Electromyogramme",
            choice:"1",
            feedback:"Cet examen permet d'objectiver l'atteinte distale sensitive.",
            reference:"<i>Zhang S. Chemotherapy-induced peripheral neuropathy and rehabilitation: A review. Semin Oncol. 2021 Jun;48(3):193-207.</i>",
          },
        ],
        categorie : [
          {name :"Biologie"},
          {name :"Imagerie"}
        ]
      },
    ],
    rapport_2: {
      title:"Voici le compte-rendu des examens réalisés :",
      paragraph: `<p>HBA1C dans la norme.</p> 
       <p>DFG à 65ml/min.</p>
      <p>IRM cérébrale sans lésion visualisée. 
      </p>

      <p>IRM rachidienne sans anomalie médullaire ou osseuse. 
      </p>
      <p>Scanner thoracique retrouvant une masse tumorale de 5cm accolée à la paroi. 
      </p>
      <p>Électromyogramme montrant des signes de neuropathie modérée des membres inférieurs.</p>
      
      `
    },

    quiz_3: [
      {
        answsers: [
          {
            title:"Douleur neuropathique périphérique (toxique liée à la chimiothérapie ou par envahissement intercostal)",
            choice:"1",
            feedback:
             "",
            reference:
             "<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300 / Andersen Hammond E, Pitz M, Shay B. Neuropathic Pain in Taxane-Induced Peripheral Neuropathy: Evidence for Exercise in Treatment. Neurorehabil Neural Repair. 2019 Oct;33(10):792-799.</i>",
          },
          {
            title:"Neuropathie diabétique",
            choice:"0",
            feedback:"La chronologie d'apparition sous chimiothérapie et l'absence d'autre atteinte microangiopathique vont plutôt contre cette hypothèse.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Neuropathie auto-immune",
            choice:"0",
            feedback:"L'atteinte bifocale et la rareté des neuropathies autoimmunes vont plutôt contre cette hypothèse.",
            reference:"<i>Zoccarato M, Grisold W, Grisold A, Poretto V, Boso F, Giometto B. Paraneoplastic Neuropathies: What's New Since the 2004 Recommended Diagnostic Criteria. Front Neurol. 2021 Oct 1;12:706169.</i>",
          },
          {
            title:"Troubles musculosquelettiques",
            choice:"0",
            feedback:"Hypothèse peu probable chez ce patient en arrêt d'activité professionnelle.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          }
        ],
      },
    ],
    quiz_4: [
      {
        answsers: [
          {
            title:"Localisation de la tumeur et des douleurs",
            choice:"1",
            feedback:
             "Envahissement direct par la tumeur.",
            reference:
             "<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Localisation périphérique des douleurs",
            choice:"1",
            feedback:"Localisation typique des neuropathies induites par la chimiothérapie.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Contexte oncologique",
            choice:"1",
            feedback:"Il y a un cancer du poumon en cours de traitement.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Comorbidités",
            choice:"0",
            feedback:"Le diabète aggrave les neuropathies induites par la chimiothérapie et constitue en outre un facteur de risque.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          }
        ],
      },
    ],

    quiz_5: [
      {
        answsers: [
          {
            title:"Adaptation du traitement antalgique systémique",
            choice:"1",
            feedback:
            "Il faut réaliser une adaptation du traitement systémique par mise en place d'une réduction des morphiniques, d'un suivi de la douleur avec calendrier, et d'un traitement de la douleur neuropathique.",
            reference:
            "<i>Urits I, Li N, Berardino K, Artounian KA, Bandi P, Jung JW, Kaye RJ, Manchikanti L, Kaye AM, Simopoulos T, Kaye AD, Torres M, Viswanath O. The use of antineuropathic medications for the treatment of chronic pain. Best Pract Res Clin Anaesthesiol. 2020 Sep;34(3):493-506.</i>",
          },
          {
            title:"Traitement antalgique topique",
            choice:"1",
            feedback:"Ce traitement s'effectue sur la localisation thoracique en regard de la tumeur.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Adaptation de la chimiothérapie",
            choice:"1",
            feedback:"Il faut une réduction des doses, un arrêt de la chimiothérapie et une poursuite de l'immunothérapie.",
            reference:"<i>Zhang S. Chemotherapy-induced peripheral neuropathy and rehabilitation: A review. Semin Oncol. 2021 Jun;48(3):193-207.</i>",
          },
          {
            title:"Evaluation du contrôle du diabète",
            choice:"0",
            feedback:"Il s'agit d'un bilan des atteintes liées au diabète, certes facteur aggravant mais n'expliquant pas la douleur.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Recherche de toxicité auto-immune",
            choice:"0",
            feedback:"Les atteintes autoimmunes sont souvent multifocales et pas uniquement périphériques.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
          {
            title:"Recherche de facteurs mécaniques",
            choice:"0",
            feedback:"Facteurs liés à l'activité professionnelle : TMS, canal carpien. Aucun rapport avec le cas.",
            reference:"<i>Sarezky J, Sachs G, Elinzano H, Stavros K. Cancer and Peripheral Nerve Disease. Clin Geriatr Med. 2021 May;37(2):289-300.</i>",
          },
        ],
      },
    ],
  },
 
  patient_4: {
    // home patient
    profil: {
      title:"Homme de 50 ans. 1m75. 90 kg. IMC : 29,4.",
      description:
      "Chauffeur poids lourd, désormais au chômage et en précarité sociale.",
    },
    histoire: {
      title:"Histoire de la maladie",
      icon:"histoire",
      paragraph:
      "Patient lombalgique chronique depuis des années, avec des difficultés professionnelles, en accident de travail puis au chômage. Opéré  dans sa phase de prise en charge en accident de travail. Souhaite que la persistance de ses douleurs soit prise en compte au titre de l'accident de travail, et non en maladie. Litige avec l'assurance maladie sur la prise en charge.",
    },
    consultation: {
      title:"Motif de consultation",
      icon:"consultation",
      paragraph:
       "Lomboradiculalgies de topographie bilatérale, chroniques, invalidantes, opérées il y a 2 ans, sans succès, avec persistances de lomboradiculalgies. Quelques semaines après la chirurgie les douleurs sont devenues permanentes, de plus en plus invalidantes, sans efficacité des traitements antalgiques habituels.",
    },
    hygiene: {
      title:"Hygiène de vie",
      icon:"hygiene",
      paragraph:
       "Il prend du tabac et de l'alcool, il ne fait pas de sport et il dort mal.",
    },
    antecedant: {
      title:"Antécédents",
      icon:"antecedent",
      paragraph:
       "Son père est décédé d'un cancer du poumon.",
    },
    traitement: {
      title:"Traitements en cours",
      icon:"traitement",
      paragraph:
       "Il prend un antalgique de pallier 2, au long cours (400 à 500 milligrammes par jours, en surdosage), un antiépileptique 100 milligrammes 3 fois par jour, un antidépresseur à 75 milligrammes le soir.",
    },
    examen: {
      title:"Examens complémentaires",
      icon:"examen",
      paragraph:
       "Selon son IRM lombaire : il souffre de discarthroses étagées et de séquelles de chirurgie rachidienne.",
    },
    synthese: {
      devenir: {
        title:"Devenir du patient",
        paragraph:
         "À la fin de la consultation, mise en place d'approches médicamenteuses centrées sur les douleurs neuropathiques, de prise en charge psycho-sociale, et de réinsertion professionnelle. Une prise en charge multidisciplinaire longue, sur plusieurs mois, au minimum 1 an.",
      },

      avis: {
        title:"Avis de l'expert",
        paragraph:"Les douleurs post-opératoires rachidiennes, ou failed-back surgery syndrome sont des douleurs complexes, neuropathiques dans leur mécanismes, avec un contexte psycho-social et professionnel majeur. Il faut mettre en place les stratégies de prise en charge des douleurs neuropathiques (recommandations SFETD 2020) et les stratégies psycho-sociales. La chirurgie itérative doit être évitée, toute nouvelle chirurgie est délétère.",
      },
    },
    // interrogatoire patient
    interrogatoire: [
      {
        title:"Quel est le type de douleurs ? Quelles sont les sensations ?",
        response:
         "Le patient ressent dans le dos et sur les jambes, des picotements et des fourmis, permanents, qui lui font l'effet d'un étau. Les douleurs sont permanentes, et ne sont pas améliorées par le repos. Il ne supporte pas le contact des habits ou des chaussettes.",
        info:"Cette question permet de caractériser les douleurs, la réponse du patient sera utile pour l'orientation diagnostique.",
      },

      {
        title:"Quel impact des douleurs sur la vie de tous les jours ?",
        response:
         "Le handicap du patient est important : il ne court plus, ne fait plus de sport et a des troubles du sommeil. Il ne bouge plus, a peur de sortir, a peur que le mouvement aggrave ses lésions.",
        info:`La douleur s'associe à des conséquences fonctionnelles importantes et à des peurs, des croyances inappropriées : ce sont les "drapeaux jaunes", catastrophisme et peur du mouvement, kinésiophobie. <i>(HAS. Fiche mémo. Prise en charge du patient présentant une lombalgie commune. Mars 2019 / Picavet HS, Vlaeyen JW, Schouten JS. Pain catastrophizing and kinesiophobia: predictors of chronic low back pain. Am J Epidemiol. 2002;156(11):1028-34)</i>`,
      },
      {
        title:"Y'a t-il des changements récents ?",
        response:
         "Le patient n'exprime aucune altération de l'état général, ni d'amaigrissement, ni de déficit quelconque.",
        info:`C'est une information cruciale à prendre en compte. Pas de "drapeau rouge".`,
      },
      {
        title:
         "Quelles sont les perspectives professionnelles du patient ?",
        response:
         "Le patient expérimente une insatisfaction au travail, une  dépression et/ou une peur du mouvement.",
        info:`C'est une information cruciale à prendre en compte. Ce sont les "drapeaux bleus", retentissement des lombalgies au travail. <i>(HAS. Fiche mémo. Prise en charge du patient présentant une lombalgie commune. Mars 2019).</i>`,
      }
     
    ],
    // quiz patient
    quiz_1: [
      {
        answsers: [
          {
            title:"Recherche de signe de Lasègue",
            choice:"1",
            feedback:
             "Il est nécessaire de faire une recherche de signe de Lasègue afin de confirmer qu'il n'existe pas de résidu discal justifiant une nouvelle opération.",
            reference:
             "<i>Kamath SU, Kamath SS. Lasègue's Sign. J Clin Diagn Res. 2017; 11(5):RG01-RG02.</i>",
          },
          {
            title:"Recherche de déficit moteur",
            choice:"1",
            feedback:"Cette recherche permet d'éliminer toute indication chirurgicale urgente.",
            reference:"<i>HAS. Fiche mémo. Prise en charge du patient présentant une lombalgie commune. Mars 2019.</i>",
          },
          {
            title:"Questionnaire DN4",
            choice:"1",
            feedback:"Ce questionnaire permet d'investiguer sur un éventuel caractère neuropathique des douleurs.",
            reference:"<i>Bouhassira D et al. Development and validation of the Neuropatic Pain Symptom Inventory. Pain. 2004;108(3):248-57.</i>",
          },
          {
            title:"Examen général",
            choice:"1",
            feedback:`Cet examen est indispensable pour constater l'absence de signes généraux et ainsi éliminer les "drapeaux rouges".`,
            reference:"<i>HAS. Fiche mémo. Prise en charge du patient présentant une lombalgie commune. Mars 2019.</i>",
          }
          
        ],
      },
    ],

    rapport: {
      title:"Voici le rapport d'examen clinique :",
      paragraph: `<p>Le patient ne présente pas de déficit moteur ni de signe de Lasègue.</p><p>Il n'y a aucun signe de gravité et la douleur n'est pas d'origine discale.</p> 
      <p>Il n'y a pas d'altération de l'état général.</p>
      <p>L'absence de signes généraux élimine les "drapeaux rouges".</p>`,
    },
    rapport_complementaire: {
      title:"Voici le compte rendu des examens réalisés",
      paragraph: `Le bilan hépatique est normal. 
      ﻿La TDM TAP sans et avec injection d'iode ne retrouve aucune lésion suspecte de récidive au niveau thoracique ni abdomimo-pelvienne d'une part et aucune anomalie pariétale dans la région de la thoracotomie (fracture de côte).`,
    },

    quiz_2: [
      {
        answsers: [
          {
            title:"CRP",
            choice:"1",
            feedback:
             "La CRP est systématique pour la recherche de syndrome inflammatoire.",
            reference:
             "",
          },
          {
            title:"Glycémie",
            choice:"1",
            feedback:"La glycémie est utile pour la recherche de diabète et de neuropathie diabétique.",
            reference:"",
          },
          {
            title:"Ca et pH",
            choice:"0",
            feedback:"Le bilan phospho-calcique est surtout utile en cas de fracture vertébrale.",
            reference:"",
          },
          {
            title:"Vitamine D",
            choice:"0",
            feedback:"La vitamine D est surtout utile en cas de fracture vertébrale.",
            reference:"",
          },
          {
            title:"IRM lombaire sans et avec injection de produit de contraste",
            choice:"1",
            feedback:"L’imagerie par résonance magnétique (IRM) avec injection de produit de contraste, grâce à sa résolution en contraste, est l’outil le plus performant. Le geste chirurgical de discectomie entraîne des phénomènes œdémato-inflammatoires qui provoquent la persistance d’un débord discal focal difficile à différencier de la hernie initiale. Après injection de produit de contraste, cette poche postopératoire se rehausse de façon hétérogène alors que la hernie ne se rehausse pas.<br>- La trace chirurgicale : l’acte chirurgical entraîne des phénomènes hémorragiques et inflammatoires sur le trajet opératoire qui vont avoir une traduction en imagerie. La caractéristique de cette cicatrice d’abord inflammatoire puis composée de tissu collagène plus ou moins vascularisé et plus ou moins innervé, est son rehaussement en imagerie.<br>- La réaction inflammatoire des plateaux vertébraux adjacents au disque opéré. Le plus souvent, elle concerne les plateaux avec une prise de contraste de la plaque chondrale.",
            reference:"<i>Baber Z, Erdek MA. Failed back surgery syndrome: current perspectives. J Pain Res. 2016;9:979-987.</i>"
          },
          {
            title:"Radio standard du rachis",
            choice:"0",
            feedback:"Ces examens sont peu utiles, il faut une indication à voir les parties molles, les racines nerveuses, les muscles, etc.",
            reference:"",
          },
          {
            title:"EMG",
            choice:"0",
            feedback:"L'EMG est peu utile. Il met en évidence une atteinte radiculaire, mais ne donne pas d'élément diagnostique ou pronostique. En post-opératoire, il peut avoir une valeur médico-légale, montrant les lésions apparues après chirurgie.",
            reference:"",
          }
        ],
        categorie : [
          {name :"Biologie"},
          {name :"Imagerie"},
          {name :"Électrophorèse"}
        ]
      },
    ],
    rapport_2: {
      title:"Voici le compte-rendu des examens réalisés :",
      paragraph: `<p>CRP : 2 mg/l.</p> 
      <p>Glycémie à jeun : 1,04 g/l</p>
      <p>IRM lombaire sans et avec injection : discopathies étagées L3-L4, L4-L5, L5-S1 avec hypersignal des plateaux vertébraux en T2 témoignant d'une discopathie, absence de résidu discal, présence d'un tissu cicatriciel épidural, absence d'hématome, absence d'argument en faveur d'une spondylodiscite.</p>`

    },

    quiz_3: [
      {
        answsers: [
          {
            title:"Douleurs neuropathiques post-opératoires du rachis",
            choice:"1",
            feedback:
             "",
            reference:"Shapiro CM. The failed back surgery syndrome: pitfalls surrounding evaluation and treatment. Phys Med Rehabil Clin N Am. 2014 May;25(2):319-40. doi: 10.1016/j.pmr.2014.01.014. PMID: 24787336.",
          },
          {
            title:"Fibrose rachidienne",
            choice:"0",
            feedback:"Ici, l'explication est erronée, ce n'est pas la fibrose épidurale qui fait mal, ce sont les lésions radiculaires persistantes et la chirurgie qui ont probablement induit les douleurs neuropathiques. On incrimine à tort la fibrose, qui est en fait une cicatrice banale post-opératoire.",
            reference:"",
          },
          {
            title:"Lipomatose épidurale",
            choice:"0",
            feedback:"Ce n'est pas le premier diagnostic, la lipomatose épidurale est une affection favorisée par le surpoids et les troubles métaboliques, elle peut exceptionnellement induire des douleurs radiculaires.",
            reference:"",
          },
          {
            title:"Dépression masquée",
            choice:"0",
            feedback:"Il s'agit ici de douleurs neuropathiques typiques, post-opératoires. Elles peuvent s'accompagner de dépression, mais celle-ci n'est pas la cause.",
            reference:"",
          },
          {
            title:"Bénéfices secondaires",
            choice:"0",
            feedback:"Le litige au sujet de la prise en charge en accident de travail ne peut résumer le tableau douloureux mais peut expliquer la chronicité.",
            reference:"",
          }
          
        ],
      },
    ],
    quiz_4: [
      {
        answsers: [
          {
            title:"Caractère neuropathique des douleurs",
            choice:"1",
            feedback:
             "Les douleurs neuropathiques sont liées à une lésion du système nerveux péri-opératoire. Le DN4 est un questionnaire utile pour le dépistage de ces douleurs neuropathiques.",
            reference:
             "<i>Bouhassira D et al. Development and validation of the Neuropatic Pain Symptom Inventory. Pain. 2004;108(3):248-57.</i>",
          },
          {
            title:"Absence de lésion compressive sur IRM",
            choice:"1",
            feedback: `Grâce à sa résolution en contraste, l'IRM est l’outil le plus performant. Le geste chirurgical de discectomie entraîne des phénomènes œdémato-inflammatoires qui provoquent la persistance d’un débord discal focal difficile à différencier de la hernie initiale. Après injection de produit de contraste, cette poche postopératoire se rehausse de façon hétérogène alors que la hernie ne se rehausse pas.
            <br> - La trace chirurgicale : l’acte chirurgical entraîne des phénomènes hémorragiques et inflammatoires sur le trajet opératoire qui vont avoir une traduction en imagerie.  La caractéristique de cette cicatrice d’abord inflammatoire puis composée de tissu collagène plus ou moins vascularisé et plus ou moins innervé, est son rehaussement en imagerie.
            <br>- La réaction inflammatoire des plateaux vertébraux adjacents au disque opéré. Le plus souvent, elle concerne les plateaux avec une prise de contraste de la plaque chondrale.`,
            reference:"",
          },
          {
            title:"Absence d'efficacité des antalgiques habituels",
            choice:"1",
            feedback:"Les douleurs neuropathiques répondent moins bien aux antalgiques habituels.",
            reference:"",
          }
        ],
      },
    ],

    quiz_5: [
      {
        answsers: [
          {
            title:"TENS (Neurostimulation électrique transcutanée) et techniques de neurostimulation",
            choice:"1",
            feedback:
             "La TENS est une approche non-médicamenteuse utile dans les douleurs neuropathiques radiculaires. Dans des cas bien sélectionnés, la stimulation médullaire doit être discutée.",
            reference:
             "<i>Kumar K, Taylor RS, Jacques L, . Spinal cord stimulation versus conventional medical management for neuropathic pain : A multicentre randomized controlled trial in patients with failed back surgery syndrome. Pain 2007;132:179–88 / Knotkova H, Hamani C, Sivanesan E, Le Beuffe MFE, Moon JY, Cohen SP, Huntoon MA. Neuromodulation for chronic pain. Lancet. 2021 May 29;397(10289):2111-2124.</i>",
          },
          {
            title:"Antalgique topique",
            choice:"1",
            feedback:"L'antalgique est un traitement des douleurs neuropathiques localisées.",
            reference:"<i>Moisset X, et al. Pharmacological and non-pharmacological treatments for neuropathic pain: Systematic review and French recommendations. Rev Neurol (Paris). 2020 May;176(5):325-352.</i>",
          },
          {
            title:"Approches psycho-sociales, mésusage de l'antalgique de pallier 2",
            choice:"1",
            feedback:"Il s'agit de la réinsertion, du soutien psychologique et addictologique.",
            reference:"<i>Kamper SJ, Apeldoorn AT, Chiarotto A, Smeets RJ, Ostelo RW, Guzman J, van Tulder MW. Multidisciplinary biopsychosocial rehabilitation for chronic low back pain: Cochrane systematic review and meta-analysis. BMJ. 2015 Feb 18;350:h444.</i>",
          },
          {
            title:"Rééducation",
            choice:"1",
            feedback:"La rééducation et le mouvement doivent accompagner toute approche antalgique du rachis, et notamment du rachis opéré.",
            reference:"<i>Amirdelfan K, Webster L, Poree L, Sukul V, McRoberts P. Treatment Options for Failed Back Surgery Syndrome Patients With Refractory Chronic Pain: An Evidence Based Approach. Spine (Phila Pa 1976). 2017 Jul 15;42 Suppl 14:S41-S52.</i>",
          },
          {
            title:"Nouvelle chirurgie du rachis",
            choice:"0",
            feedback:"Il s'agit ici de douleurs neuropathiques post-opératoires, il ne faut pas de nouvelle chirurgie : elle ne fera qu'aggraver les douleurs neuropathiques.",
            reference:"<i>Assaker R, Zairi F. Failed back surgery syndrome: to re-operate or not to re-operate? A retrospective review of patient selection and failures. Neurochirurgie. 2015 Mar;61 Suppl 1:S77-82.</i>",
          },
          {
            title:"Infiltrations du rachis",
            choice:"0",
            feedback:"Concernant les infiltrations du rachis : elles sont inutiles et contre-indiquées sur rachis opéré.",
            reference:"<i>ANSM. Mise au point. Risque de paraplégie / tétraplégie lié aux injections radioguidées de glucocorticoïdes au rachis lombaire ou cervical. Mars 2011.</i>",
          },
          {
            title:"Médicaments antalgiques systémiques",
            choice:"0",
            feedback:"Il faut les envisager si les traitements locaux sont insuffisants : antiépileptiques et antidépresseurs, mais ils sont déjà prescrits, ils sont peu efficaces, et provoquent de la somnolence.",
            reference:"",
          },
          {
            title:"Éducation du patient",
            choice:"1",
            feedback:"Il est indispensable d'éduquer les patients, de les impliquer dans une approche globale.",
            reference:"<i>Wood L, Hendrick PA. A systematic review and meta-analysis of pain neuroscience education for chronic low back pain: Short-and long-term outcomes of pain and disability. Eur J Pain. 2019 Feb;23(2):234-249. doi: 10.1002/ejp.1314. Epub 2018 Oct 14. PMID: 30178503.</i>",
          }
        ],
      },
    ],
  },


};
